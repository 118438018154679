




import { AuthService } from '@consolidate/shared/util-auth';
import Vue from 'vue';

export default Vue.extend({
  name: 'LoginCallback',
  async created(): Promise<void> {
    await AuthService.processLoginCallback();
    window.location.href = '../';
  },
});
